import section0 from 'sections/activation/adapter';
import section1 from 'sections/card/adapter';
import section2 from 'sections/card-use-settings/adapter';
import section3 from 'sections/checks/adapter';
import section4 from 'sections/direct-deposit-form/adapter';
import section5 from 'sections/disclosure-dictionary/adapter';
import section6 from 'sections/layout/adapter';
import section8 from 'sections/me-to-me-transfer/adapter';
import section9 from 'sections/mobile-check-load/adapter';
import section10 from 'sections/move-money/adapter';
import section11 from 'sections/move-money-subaccount/adapter';
import section12 from 'sections/offers-intro/adapter';
import section13 from 'sections/pay-bills/adapter';
import section15 from 'sections/paypal-transfers/adapter';
import section16 from 'sections/points/adapter';
import section17 from 'sections/post-install-multi-screen-acquisition-flow/adapter';
import section18 from 'sections/pre-onboarding/adapter';
import section20 from 'sections/premier-plan/adapter';
import section22 from 'sections/recover/adapter';
import section23 from 'sections/refer-a-friend/adapter';
import section25 from 'sections/spend-money/adapter';
import section26 from 'sections/post-handoff-to-activate-register/adapter';
import app0 from 'apps/anytime-alerts/adapter';
import app1 from 'apps/anytime-alerts-upgrade/adapter';
import app2 from 'apps/bank-transfers/adapter';
import app3 from 'apps/benefit-center/adapter';
import app4 from 'apps/billpay/adapter';
import app5 from 'apps/change-password/adapter';
import app6 from 'apps/change-username/adapter';
import app7 from 'apps/check-deposit/adapter';
import app8 from 'apps/close-account/adapter';
import app9 from 'apps/connected-banks/adapter';
import app10 from 'apps/contact-us/adapter';
import app11 from 'apps/dashboard/adapter';
import app14 from 'apps/dashboard-lite/adapter';
import app15 from 'apps/debit-card-transfers/adapter';
import app16 from 'apps/debit-card-transfers-disbursement/adapter';
import app17 from 'apps/digital-wallet/adapter';
import app18 from 'apps/direct-deposit/adapter';
import app19 from 'apps/echecks/adapter';
import app21 from 'apps/external-loyalty/adapter';
import app22 from 'apps/family-cards/adapter';
import app23 from 'apps/feedback/adapter';
import app24 from 'apps/feeplan/adapter';
import app25 from 'apps/fraud-detection/adapter';
import app26 from 'apps/handoff/adapter';
import app27 from 'apps/interstitials/adapter';
import app28 from 'apps/locations/adapter';
import app29 from 'apps/login/adapter';
import app31 from 'apps/logout/adapter';
import app32 from 'apps/manage/adapter';
import app34 from 'apps/money-gram/adapter';
import app36 from 'apps/overdraft/adapter';
import app38 from 'apps/points-learn-more/adapter';
import app40 from 'apps/rent-track/adapter';
import app41 from 'apps/restrictions-readonly/adapter';
import app42 from 'apps/savings/adapter';
import app43 from 'apps/savings-transfer/adapter';
import app44 from 'apps/secure-inbox/adapter';
import app45 from 'apps/secure-upload/adapter';
import app46 from 'apps/security/adapter';
import app48 from 'apps/speedy-rewards/adapter';
import app49 from 'apps/statements/adapter';
import app51 from 'apps/survey/adapter';
import app52 from 'apps/tours/adapter';
import app53 from 'apps/upgrade/adapter';
import app54 from 'apps/western-union/adapter';
export default (appModule) => {
  section0(appModule);
  section1(appModule);
  section2(appModule);
  section3(appModule);
  section4(appModule);
  section5(appModule);
  section6(appModule);
  section8(appModule);
  section9(appModule);
  section10(appModule);
  section11(appModule);
  section12(appModule);
  section13(appModule);
  section15(appModule);
  section16(appModule);
  section17(appModule);
  section18(appModule);
  section20(appModule);
  section22(appModule);
  section23(appModule);
  section25(appModule);
  section26(appModule);
  app0(appModule);
  app1(appModule);
  app2(appModule);
  app3(appModule);
  app4(appModule);
  app5(appModule);
  app6(appModule);
  app7(appModule);
  app8(appModule);
  app9(appModule);
  app10(appModule);
  app11(appModule);
  app14(appModule);
  app15(appModule);
  app16(appModule);
  app17(appModule);
  app18(appModule);
  app19(appModule);
  app21(appModule);
  app22(appModule);
  app23(appModule);
  app24(appModule);
  app25(appModule);
  app26(appModule);
  app27(appModule);
  app28(appModule);
  app29(appModule);
  app31(appModule);
  app32(appModule);
  app34(appModule);
  app36(appModule);
  app38(appModule);
  app40(appModule);
  app41(appModule);
  app42(appModule);
  app43(appModule);
  app44(appModule);
  app45(appModule);
  app46(appModule);
  app48(appModule);
  app49(appModule);
  app51(appModule);
  app52(appModule);
  app53(appModule);
  app54(appModule);
};